<template>
    <div class="coupons">
        <div class="coupons-title">
            <div class="back" @click="backClick">
                <img src="@/assets/images/personal/z.png" />
                <span>Back</span>
            </div>
            <h5>Coupons</h5>
        </div>
        <div v-show="!emptyShow" class="coupons-main" v-loading="loading">
            <div class="main-title">
                <span :class="unusedCoupons" @click="couponSwitch(0)">Unused Coupons</span>
                <span :class="expiredCoupons" @click="couponSwitch(1)">Expired Coupons</span>
            </div>
            <div class="main-list">
                <div class="list" v-show="tabShow == 0">
                    <div :class="item.state == 1 ? 'couponsUnused couponsUnused1' : 'couponsUnused'" v-for="(item, index) in couponsList" :key="index">
                        <div class="save">
                            <span>${{ item.money }}</span>
                            <!-- <p>SAVE</p> -->
                            <p>OFF</p>
                        </div>
                        <span>For orders over US${{ item.at_least }}</span>
                        <p>{{ item.timeText }}</p>
                        <!-- <h5>NEW USER</h5> -->
                        <h5>COUPON</h5>
                    </div>
                </div>
                <div class="list" v-show="tabShow == 1">
                    <div :class="item.state == 2 ? 'couponsUnused couponsUnused2' : 'couponsUnused couponsUnused3'" v-for="(item, index) in couponsList1" :key="index">
                        <div class="save">
                            <span>${{ item.money }}</span>
                            <p>OFF</p>
                        </div>
                        <span>For orders over US${{ item.at_least }}</span>
                        <p>{{ item.timeText }}</p>
                        <h5>COUPON</h5>
                    </div>
                </div>
            </div>
        </div>
        <div v-show="emptyShow" class="coupons-empty" v-loading="loading">
            <img src="@/assets/images/personal/couponsEmpty.png">
            <span>No coupons yet</span>
        </div>
    </div>
</template>

<style>
.about-content{
    background: #FFFFFF;
}
</style>
<style lang="scss" scoped>
@import './css/coupons.scss';
</style>

<script>
import coupons from "./js/coupons.js"

export default {
    name: "coupons",
    components: {
        
    },
    mixins: [coupons]
}
</script>
