import { couponList } from "@/api/member/member"

export default {
    name: "coupons",
    components: {
        
    },
    data: () => {
        return {
            unusedCoupons: "ative",
            expiredCoupons: "",
            couponsBackground: "",
            tabShow: 0,
            couponsList: [],
            emptyShow: true,
            couponsCode: {
                "code": 0,
                "message": "操作成功",
                "data": {
                  "page_count": 1,
                  "count": 3,
                  "list": [
                    {
                      "platformcoupon_name": "Valentine Special",
                      "use_order_id": "",
                      "platformcoupon_id": 4,
                      "platformcoupon_type_id": 1,
                      "platformcoupon_code": "aw8w4jwe",
                      "member_id": 1,
                      "at_least": "2.00",
                      "money": "3.00",
                      "state": 1,
                      "get_type": 2,
                      "fetch_time": 1647487296,
                      "use_time": 0,
                      "end_time": 1655919549,
                      "username": "ljm_123",
                      "order_no": null,
                      "use_scenario": 1
                    },
                    {
                      "platformcoupon_name": "Valentine Special",
                      "use_order_id": "",
                      "platformcoupon_id": 2,
                      "platformcoupon_type_id": 2,
                      "platformcoupon_code": "55l2ydyb",
                      "member_id": 1,
                      "at_least": "90.00",
                      "money": "10.00",
                      "state": 1,
                      "get_type": 2,
                      "fetch_time": 1643197370,
                      "use_time": 0,
                      "end_time": 1655919730,
                      "username": "ljm_123",
                      "order_no": null,
                      "use_scenario": 1
                    },
                    {
                      "platformcoupon_name": "Valentine Special",
                      "use_order_id": "",
                      "platformcoupon_id": 1,
                      "platformcoupon_type_id": 4,
                      "platformcoupon_code": "n5fwfmer",
                      "member_id": 1,
                      "at_least": "210.00",
                      "money": "35.00",
                      "state": 1,
                      "get_type": 2,
                      "fetch_time": 1643197355,
                      "use_time": 0,
                      "end_time": 1655919830,
                      "username": "ljm_123",
                      "order_no": null,
                      "use_scenario": 1
                    }
                  ]
                },
                "timestamp": 1648619794
            },
            couponsList1: [],
            loading: true
        }
    },
    watch: {
        '$route': 'fetchData',
    },
    created() {
        this.getCouponList();
        this.getCouponList1();
        // this.getLength();       
    },
    mounted() { 
        
    },
    methods: {
        fetchData() {
            this.getCouponList();
            this.getCouponList1();
        },
        getLength() {
            if(this.couponsList.length <= 0){
                this.emptyShow = true;
            }else{
                this.emptyShow = false;
            }
        },
        getCouponList() {
            couponList({
                couponsource: "0",
                state: "1",
             }).then( res =>{
                // res = this.couponsCode;
                if(res.code == 0 && res.data.count > 0){
                    res.data.list.forEach(item => {
                        this.$set(item, "timeText", this.getTime(item.fetch_time, item.end_time));
                    });
                    this.couponsList = res.data.list;
                    this.loading = false;
                    this.emptyShow = false;
                } else {
                    this.emptyShow = true;
                    this.loading = false;
                }
            }).catch( err =>{
                this.emptyShow = true;
                this.loading = false;
            })
        },
        getCouponList1() {
            couponList({
                couponsource: "0",
                state: "2",
             }).then( res =>{
                // res = this.couponsCode;
                if(res.code == 0 && res.data.count > 0){
                    res.data.list.forEach(item => {
                        this.$set(item, "timeText", this.getTime(item.fetch_time, item.end_time));
                    });
                    this.couponsList1 = res.data.list;
                    this.loading = false;
                    this.emptyShow = false;
                } else {
                    this.emptyShow = true;
                    this.loading = false;
                }
            }).catch( err =>{
                this.emptyShow = true;
                this.loading = false;
            })
            couponList({
                couponsource: "0",
                state: "3",
             }).then( res =>{
                // res = this.couponsCode;
                if(res.code == 0 && res.data.count > 0){
                    res.data.list.forEach(item => {
                        this.$set(item, "timeText", this.getTime(item.fetch_time, item.end_time));
                    });
                    this.couponsList1 =  this.couponsList1.concat(res.data.list);
                    this.loading = false;
                    this.emptyShow = false;
                } else {
                    this.emptyShow = true;
                    this.loading = false;
                }
            }).catch( err =>{
                this.emptyShow = true;
                this.loading = false;
            })
        },
        getTime(fetch_time, end_time) {
            let fetchTime = new Date(fetch_time * 1000);
            let year = fetchTime.getFullYear();
            let month = fetchTime.getMonth() + 1 < 10 ? "0" + (fetchTime.getMonth() + 1) : fetchTime.getMonth() + 1;
            let day = fetchTime.getDate() < 10 ? "0" + (fetchTime.getDate()) : fetchTime.getDate();

            let endTime = new Date(end_time * 1000);
            let y = endTime.getFullYear();
            let m = endTime.getMonth() + 1 < 10 ? "0" + (endTime.getMonth() + 1) : endTime.getMonth() + 1;
            let d = endTime.getDate() < 10 ? "0" + (endTime.getDate()) : endTime.getDate();
            
            return day + "/" + month + "/" + year + " ~ " + d + "/" + m + "/" + y;
        },
        couponSwitch(val) {
            if(val == 0){
                this.tabShow = val;
                this.unusedCoupons = "ative";
                this.expiredCoupons = "";
                // this.couponsBackground = "couponsUnused couponsUnused1";
            }else{
                this.tabShow = val;
                this.unusedCoupons = "";
                this.expiredCoupons = "ative";
                // this.couponsBackground = "couponsUnused couponsUnused2";
            }
        },
        backClick() {
            this.$router.go(-1);
        }
    }
}